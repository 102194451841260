const StatistiquesIcon = () => {
   return (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
         <title>Statistiques</title>
         <path d="M19.833 7.333a2.333 2.333 0 0 1 4.667 0v14a2.333 2.333 0 1 1-4.667 0v-14ZM11.667 12a2.333 2.333 0 1 1 4.666 0v9.333a2.333 2.333 0 1 1-4.666 0V12Zm-5.834 4.667A2.333 2.333 0 0 0 3.5 19v2.333a2.333 2.333 0 1 0 4.667 0V19a2.333 2.333 0 0 0-2.334-2.333Z" />
      </svg>
   );
};

export default StatistiquesIcon;
