const SprintIcon = () => {
   return (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
         <title>Mon tableau</title>
         <g clipPath="url(#SprintIcon)">
            <path d="M23.602 12.924a.876.876 0 0 0-.545-.62l-6.303-2.363 1.604-8.02a.874.874 0 0 0-1.498-.769L4.61 14.277a.875.875 0 0 0 .333 1.416l6.303 2.364-1.604 8.02a.875.875 0 0 0 1.498.77L23.39 13.72a.874.874 0 0 0 .212-.797Z" />
         </g>
         <defs>
            <clipPath id="SprintIcon">
               <path fill="#fff" d="M0 0h28v28H0z" />
            </clipPath>
         </defs>
      </svg>
   );
};

export default SprintIcon;
